// src/PUMPA.js
import React from 'react';

const PUMPA = () => {
  const handleImageClick = (url) => () => {
    window.location.href = url;
  };

  return (
    <main style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: 'auto', 
      margin: '0 auto', 
      fontFamily: 'sans-serif', 
      color: '#170E0E', 
      backgroundColor: '#252525',
      padding: '16px',
      boxSizing: 'border-box'
    }}>
      {/* Main Content */}
      <div style={{ textAlign: 'center', color: 'white', marginBottom: '12px' }}>
        <h1 style={{ fontSize: '24px', fontWeight: '600', margin: '0' }}>PUMPA</h1>
        <div style={{ fontSize: '18px', fontStyle: 'italic' }}>Workout Tracker Gym Log
        </div>
      </div>
      
      <div style={{ marginBottom: '12px', width: '100%', maxWidth: '256px' }}>
        <a href="https://pumpa.app">
          <img src="/elephant.svg" alt="Pumpa Logo" style={{ width: '100%', height: 'auto', borderRadius: 16 }} />
        </a>
      </div>
      
      <div style={{ marginBottom: '12px', width: '100%', maxWidth: '256px' }}>
        <a href="https://apps.apple.com/us/app/pumpa-workout-tracker-gym-log/id6504921196">
          <img src="/app-store-badge.svg" alt="Download on the App Store" style={{ width: '100%', height: 'auto' }} />
        </a>
      </div>
      
      <div style={{ marginBottom: '12px', width: '100%', maxWidth: '256px' }}>
        <a href="https://play.google.com/store/apps/details?id=io.johntech.pumpa&hl=en">
          <img src="/google-play-badge.png" alt="Get it on Google Play" style={{ width: '256px', height: 'auto' }} />
        </a>
      </div>

      {/* Social Links */}
      <div style={{ 
        width: '100%', 
        textAlign: 'center', 
        marginTop: '12px'
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          {/* <a href="https://www.tiktok.com/@howicu" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>TikTok</a> */}
          <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>Terms & Conditions</a>
          <a href="/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>Privacy Policy</a>        </div>
          <a href="/support" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>Support</a>
      </div>

      {/* "More from me" Section */}
      <div style={{ 
        width: '100%', 
        maxWidth: '600px', 
        marginTop: '40px', 
        textAlign: 'center', 
        color: 'white', 
        borderTop: '1px solid #444', 
        paddingTop: '16px' 
      }}>
        <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}>More from me</h2>
        
        <div style={{ marginBottom: '16px', width: '100%', maxWidth: '180px', margin: '0 auto' }}>
          <a href="https://howicu.com">
            <img src="/logo.svg" alt="Howicu Logo" style={{ width: '100%', height: 'auto', borderRadius: 16 }} />
          </a>
        </div>

        <div style={{ color: 'white', fontSize: '16px' }}>
          Howicu - How We See Each Other
        </div>
      </div>
    </main>
  );
};

export default PUMPA;
