// src/Support.js
import React from 'react';
import { Link } from 'react-router-dom';

const Support = () => {
  const htmlContent = {
    __html: `
      <strong>Support</strong><br>
      <p>If you need assistance or have any feedback for PUMPA, please reach out to for support.</p><br>
      <p>Contact me at: <a href="mailto:johntechau@gmail.com">johntechau@gmail.com</a></p><br>
      <p>Thank you for using PUMPA!</p>
    `
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'sans-serif', color: '#170E0E', textAlign: 'left', backgroundColor: '#FFFFFF' }}>
      <h1>Support</h1>
      {/* Back button at the top of the page */}
      <div style={{ marginBottom: '20px' }}>
        <Link to="/" style={{ textDecoration: 'none', color: '#007bff' }}>Back to Home</Link>
      </div>
      <div dangerouslySetInnerHTML={htmlContent}></div>
    </div>
  );
}

export default Support;
